<template>
    <b-button :variant="variant"
              :class="[size, classButton]"
              :type="type"
              v-html="text"
              @click="button($event)"
    />
</template>

<script>
    export default {
        name: "UButton",
        props: {
            variant: {type: String, default: 'u-button'},
            text: {type: String, default: 'Как это работает? default'},
            size: {type: String, default: 'medium'},
            classButton: {type: String, default: ''},
            type: {type: String, default: 'button'},
        },
        methods: {
            button: function (e) {
                this.$emit('click', e);
            },
        },
    }
</script>

<style scoped lang="scss">
    .btn {
        &-u-button {
            font-size: $font-size-base;
            line-height: $font-size-base;
            color: #ffffff;
            background: $redBtn;
            transition: all .2s ease-in-out;
            border-radius: 3px;
            @include _lg {
                font-size: $font-size-small;
                line-height: $font-size-small;

            }
            &_blue{
                background: $site-color;
                &:hover{
                    background:$site-color-hover!important;
                }
            }
            &.medium {
                padding: 21px 32px;
                @include _lg {
                    padding: 11px 17px 12px 17px;
                }

                &.long {
                    padding: 21px 97px;
                    @include _lg {
                        padding: 11px 50px 12px 50px;
                    }
                }
            }

            &.small {
                padding: 10px 104px 12px 104px;
                @include _sm {
                    padding: 10px 24px 12px 24px;
                }
            }

            &:hover {
                background: $redBtnHover;
            }

            &:focus,
            &:active {
                outline: none;
                box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
            }
        }
    }
</style>